<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header title="설비그룹 상세보기" :is-sticky="true" />
    <div class="container">
      <subtitle subtitle="설비그룹 정보" />
      <template v-if="!isLoading">
        <ul class="detail-list" :class="{ 'factory-list': isFactoryComp }">
          <detail-list-row
            :is-register="false"
            v-for="(eqpInfo, index) in equipGroupInfo"
            :key="index"
            :info="eqpInfo"
            :inputKey="eqpInfo.key"
            :input-value="equipmentGroupData[eqpInfo.key]"
            :text-count="eqpInfo.textCount"
            :maxlength="eqpInfo.maxlength"
            :placeholder="eqpInfo.placeholder"
            :fileName="showedFileName"
            :is-loading="eqpInfo.key === 'manualFileUrl' ? isLoadingFile : false"
            @selectUploadData="onSelectUploadData"
            @deleteUploadData="onDeleteUploadData"
            @saveChangeValue="onSaveInputValue"
            @saveInputValue="onSaveEditValue"
            @createAlertSettingsItem="onCreateAlertSettingsItem"
            @clickCancelButton="onClickCancelButton"
          >
            <span slot="label" class="label" :class="{ 'label-required': eqpInfo.isRequired }">
              {{ eqpInfo.label }}
            </span>
            <span slot="contents" class="contents">
              <button
                v-if="hasEqpGroupData.includes(eqpInfo.key)"
                @click.stop="onClickFileOrLink({ [eqpInfo.clickType]: equipmentGroupData[eqpInfo.key] })"
                :class="{
                  'has-no-data': !equipmentGroupData[eqpInfo.key],
                  'group-name': eqpInfo.key === 'groupName',
                }"
              >
                {{ equipmentGroupData[eqpInfo.name] || equipmentGroupData[eqpInfo.key] || '-' }}
              </button>
            </span>
          </detail-list-row>
        </ul>
        <div class="mb-50 ml-20 mr-20">
          <alert-settings-list
            :alert-settings-list="equipmentGroupData.alertSettings"
            :group-type="equipmentGroupData.groupType"
            @selectUploadData="onSelectUploadData"
            @deleteUploadData="onDeleteUploadData"
            @deleteAlertSetting="togglePopup"
            @saveAlertSetting="onSetAlertSettingType"
            @deleteAlertItem="onDeleteAlertItem"
          />
        </div>
        <subtitle subtitle="연결된 설비 정보" class="mb-20" />
        <div class="mb-30">
          <linked-group-equipment-list
            :linked-equip-list="equipmentGroupData.equipments"
            :group-type="equipmentGroupData.groupType"
          />
        </div>
        <list-bottom-button-box>
          <button-basic
            slot="button"
            color="gray-border-1"
            size="m"
            :width="150"
            @click="onShowDeleteEqpGroupPopup"
            v-if="isNotFactoryEquipment"
          >
            설비그룹 삭제
          </button-basic>
        </list-bottom-button-box>
      </template>
    </div>
    <confirm-popup
      :is-show="showedPopupName === 'ConfirmPopup'"
      :contents-key="popupContentsKey"
      @close="onCloseConfirmPopup"
      @confirm="onConfirmInPopup"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import fileUpload from '@/asset/js/fileUpload';
import AlertSettingsList from '@/view/equipmentGroups/equipmentGroupDetail/alertSettingsList/AlertSettingsList';
import LinkedGroupEquipmentList from '@/view/equipmentGroups/equipmentGroupDetail/linkedGroupEquipmentList/LinkedGroupEquipmentList';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import manufacturerInfo from '@/view/equipmentGroups/equipmentGroupDetail/equipmentGroupDetailData/ManufacturerInfo.json';
import factoryInfo from '@/view/equipmentGroups/equipmentGroupDetail/equipmentGroupDetailData/FactoryInfo.json';
import factorySelfInfo from '@/view/equipmentGroups/equipmentGroupDetail/equipmentGroupDetailData/FactorySelfInfo.json';

export default {
  name: 'EquipmentGroupDetail',
  props: [],
  data() {
    return {
      equipGroupInfo: '',
      equipGroupId: this.$route.params.egId,
      equipmentGroupData: {},
      selectedAlertSettingsItem: null,
      allFilename: '',
      fileBase64: '',
      imageUrl: '',
      uploadType: '',
      photoId: '',
      savedFileName: '',
      showedFileName: '',
      showedPopupName: '',
      popupContentsKey: '',
      alertId: '',
      isShowEditMode: false,
      fileRscData: {},
      hasEqpGroupData: ['groupName', 'manualFileUrl', 'manualUrl'],
      isLoadingFile: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
    ...mapGetters('users', ['isFactoryComp', 'isManufacturerComp']),
    isNotFactoryEquipment() {
      return (
        this.equipmentGroupData.groupType === 'F' ||
        (this.isManufacturerComp && this.equipmentGroupData.groupType === 'M')
      );
    },
  },
  created() {
    this.equipmentGroupData = this.getEquipGroupData(this.equipGroupId);
  },
  mounted() {},
  watch: {
    fileBase64() {
      if (this.fileBase64) {
        const rscObj = {
          rscName: this.allFilename,
          rscValue: this.fileBase64,
        };
        switch (this.uploadType) {
          case 'file':
            this.fileRscData = rscObj;
            this.showedFileName = this.allFilename;
            this.initializeManualFile();
            break;
          case 'photo':
            this.postPhotoRsc(rscObj);
            this.initializeManualFile();
            break;
        }
      }
    },
  },
  methods: {
    ...mapActions('manufacturerEqpGroups', [
      'POST_MANUFACTURER_EQP_GROUP_RSC',
      'DELETE_MANUFACTURER_EQP_GROUP_RSC',
      'GET_MANUFACTURER_EQP_GROUP',
      'PUT_MANUFACTURER_EQP_GROUP',
      'DELETE_MANUFACTURER_EQP_GROUP',
    ]),
    ...mapActions('factoryEqpGroups', [
      'POST_FACTORY_EQP_GROUP_RSC',
      'DELETE_FACTORY_EQP_GROUP_RSC',
      'GET_FACTORY_EQP_GROUP',
      'PUT_FACTORY_EQP_GROUP',
      'DELETE_FACTORY_EQP_GROUP',
    ]),
    ...mapActions('manufacturerAlert', [
      'POST_MANUFACTURER_ALERT_RSC',
      'DELETE_MANUFACTURER_ALERT_RSC',
      'DELETE_MANUFACTURER_ALERT',
      'POST_MANUFACTURER_ALERT',
      'PUT_MANUFACTURER_ALERT',
    ]),
    ...mapActions('factoryAlert', [
      'POST_FACTORY_ALERT_RSC',
      'DELETE_FACTORY_ALERT_RSC',
      'DELETE_FACTORY_ALERT',
      'POST_FACTORY_ALERT',
      'PUT_FACTORY_ALERT',
    ]),
    async getEquipGroupData(gid) {
      try {
        this.isLoading = true;
        switch (this.currentCompany) {
          case 'M':
            this.equipmentGroupData = await this.GET_MANUFACTURER_EQP_GROUP(gid);
            this.equipGroupInfo = manufacturerInfo;
            break;
          case 'F':
            this.equipmentGroupData = await this.GET_FACTORY_EQP_GROUP(gid);
            this.setFactoryEqpInfo(this.equipmentGroupData.groupType);
            break;
        }
        this.savedFileName = this.equipmentGroupData.manualFileName;
        this.showedFileName = this.savedFileName;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      } finally {
        this.isLoading = false;
      }
    },
    setFactoryEqpInfo(factoryType) {
      if (factoryType === 'M') {
        this.equipGroupInfo = factoryInfo;
      } else {
        this.equipGroupInfo = factorySelfInfo;
      }
    },
    onSaveInputValue(inputKey, changedValue) {
      this.equipmentGroupData[inputKey] = changedValue;
    },
    async onSaveEditValue(inputKey, changedValue) {
      this.equipmentGroupData[inputKey] = changedValue;
      try {
        const manualFileLoading = inputKey === 'manualFileUrl';
        this.isLoadingFile = manualFileLoading;
        if (Object.keys(this.fileRscData).length !== 0) {
          await this.postFileRsc();
        } else if (
          Object.keys(this.fileRscData).length === 0 &&
          !this.showedFileName &&
          this.equipmentGroupData.rscFileId
        ) {
          await this.deleteFileRsc();
        }
        await this.putEquipGroup();
        this.isLoadingFile = false;
        if (inputKey === 'manualFileUrl') {
          this.equipmentGroupData = this.getEquipGroupData(this.equipGroupId);
        }
      } finally {
        this.isLoadingFile = false;
      }
    },
    async postFileRsc() {
      switch (this.currentCompany) {
        case 'M':
          this.equipmentGroupData.rscId = await this.POST_MANUFACTURER_EQP_GROUP_RSC(this.fileRscData);
          break;
        case 'F':
          this.equipmentGroupData.rscId = await this.POST_FACTORY_EQP_GROUP_RSC(this.fileRscData);
          break;
      }
    },
    async deleteFileRsc() {
      switch (this.currentCompany) {
        case 'M':
          await this.DELETE_MANUFACTURER_EQP_GROUP_RSC(this.equipmentGroupData.rscFileId);
          break;
        case 'F':
          await this.DELETE_FACTORY_EQP_GROUP_RSC(this.equipmentGroupData.rscFileId);
          break;
      }
    },
    async putEquipGroup() {
      switch (this.currentCompany) {
        case 'M':
          await this.PUT_MANUFACTURER_EQP_GROUP([this.equipGroupId, this.equipmentGroupData]);
          break;
        case 'F':
          await this.PUT_FACTORY_EQP_GROUP([this.equipGroupId, this.equipmentGroupData]);
          break;
      }
    },
    onSelectUploadData({ event, alertSettingsItem = null, type }) {
      if (alertSettingsItem) this.selectedAlertSettingsItem = alertSettingsItem;
      this.uploadType = type;
      fileUpload(this, event.target, true, false);
    },
    async postPhotoRsc(rscObj) {
      switch (this.currentCompany) {
        case 'M':
          this.photoId = await this.POST_MANUFACTURER_ALERT_RSC(rscObj);
          break;
        case 'F':
          this.photoId = await this.POST_FACTORY_ALERT_RSC(rscObj);
          break;
      }
      this.selectedAlertSettingsItem.rscFileId = this.photoId;
      this.selectedAlertSettingsItem.refImgUrl = this.imageUrl;
    },
    onDeleteUploadData(uploadType, alertSettingsItem) {
      this.selectedAlertSettingsItem = alertSettingsItem;
      switch (uploadType) {
        case 'file':
          this.showedFileName = '';
          this.fileRscData = {};
          break;
        case 'photo':
          this.deletePhotoRsc(alertSettingsItem.rscFileId);
          this.photoId = '';
          break;
      }
      this.allFilename = '';
    },
    async deletePhotoRsc(rscFileId) {
      switch (this.currentCompany) {
        case 'M':
          await this.DELETE_MANUFACTURER_ALERT_RSC(rscFileId);
          break;
        case 'F':
          await this.DELETE_FACTORY_ALERT_RSC(rscFileId);
          break;
      }
      this.selectedAlertSettingsItem.rscFileId = '';
      this.selectedAlertSettingsItem.refImgUrl = '';
    },
    onMoveEquipGroupList() {
      this.$router.push(`/equipment-groups`).catch((err) => err);
    },
    onCreateAlertSettingsItem() {
      const newAlertSettingsItem = this.getEmptyAlertSettingsItem();
      this.equipmentGroupData.alertSettings.push(newAlertSettingsItem);
    },
    getEmptyAlertSettingsItem() {
      return {
        issueCode: null,
        alertType: 'WEB',
        title: '',
        description: '',
        rscFileId: 0,
        isEditMode: true,
      };
    },
    async onDeleteEquipGroup() {
      switch (this.currentCompany) {
        case 'M':
          await this.DELETE_MANUFACTURER_EQP_GROUP(this.equipmentGroupData.id);
          break;
        case 'F':
          await this.DELETE_FACTORY_EQP_GROUP(this.equipmentGroupData.id);
          break;
      }
      this.togglePopup('ConfirmPopup', 'DELETE_EQP_GROUP_SUCCESS');
    },
    async onDeleteAlertSetting() {
      switch (this.currentCompany) {
        case 'M':
          await this.DELETE_MANUFACTURER_ALERT(this.alertId);
          break;
        case 'F':
          await this.DELETE_FACTORY_ALERT(this.alertId);
          break;
      }
      this.togglePopup();
      this.alertId = '';
      this.equipmentGroupData = this.getEquipGroupData(this.equipGroupId);
    },
    onShowDeleteEqpGroupPopup() {
      this.popupContentsKey =
        this.equipmentGroupData.equipments.length > 0 ? 'IMPOSSIBLE_DELETE_EQP_GROUP' : 'DELETE_EQP_GROUP';
      this.togglePopup('ConfirmPopup', this.popupContentsKey);
    },
    togglePopup(popupName, popupContentsKey, alertId) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
      this.alertId = alertId || '';
    },
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_EQP_GROUP':
          this.onDeleteEquipGroup();
          break;
        case 'DELETE_ALERT_SETTING':
          this.onDeleteAlertSetting();
          break;
        default:
          break;
      }
    },
    onCloseConfirmPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_EQP_GROUP_SUCCESS':
          this.togglePopup();
          this.$router.push(`/equipment-groups`).catch((err) => err);
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onSetAlertSettingType(alertSettingsItem) {
      if (alertSettingsItem.isEditMode) {
        this.onSaveAlertSetting(alertSettingsItem);
      } else {
        this.onSaveEditAlertSetting(alertSettingsItem);
      }
    },
    async onSaveAlertSetting(alertSettingsItem) {
      alertSettingsItem.equipmentGroupId = this.equipGroupId;
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_ALERT(alertSettingsItem);
          break;
        case 'F':
          await this.POST_FACTORY_ALERT(alertSettingsItem);
          break;
      }
      this.equipmentGroupData = this.getEquipGroupData(this.equipGroupId);
    },
    async onSaveEditAlertSetting(alertSettingsItem) {
      alertSettingsItem.equipmentGroupId = this.equipGroupId;
      switch (this.currentCompany) {
        case 'M':
          await this.PUT_MANUFACTURER_ALERT([alertSettingsItem.id, alertSettingsItem]);
          break;
        case 'F':
          await this.PUT_FACTORY_ALERT([alertSettingsItem.id, alertSettingsItem]);
          break;
      }
      this.equipmentGroupData = this.getEquipGroupData(this.equipGroupId);
    },
    onDeleteAlertItem(AlertItemIndex) {
      this.equipmentGroupData.alertSettings.splice(AlertItemIndex, 1);
    },
    onClickCancelButton() {
      this.showedFileName = this.savedFileName;
      this.fileRscData = {};
    },
    initializeManualFile() {
      this.allFilename = '';
      this.fileBase64 = '';
      const upFileManual = document.getElementById('upFileManual');
      if (upFileManual) {
        upFileManual.value = '';
      }
    },
    onClickFileOrLink({ file, link }) {
      if (file) {
        window.open(file);
      } else if (link) {
        link.startsWith('http') ? window.open(link) : window.open(`http://${link}`);
      }
    },
  },
  components: {
    MainSectionHeader,
    Subtitle,
    DetailListRow,
    AlertSettingsList,
    LinkedGroupEquipmentList,
    ListBottomButtonBox,
    ConfirmPopup,
  },
};
</script>

<style scoped lang="scss">
@import '../equipmentGroupRegister/EquipmentGroupRegister';
</style>
