var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c("span", [_vm._v(_vm._s(_vm.index + 1))]),
    _c(
      "span",
      [
        _vm.isEditing || _vm.alertSettingsItem.isEditMode
          ? _c("input-box", {
              attrs: {
                type: "text",
                id: "alert-code-" + _vm.index,
                autocomplete: "off",
                "is-flex": true,
              },
              model: {
                value: _vm.alertSettingsItem.issueCode,
                callback: function ($$v) {
                  _vm.$set(_vm.alertSettingsItem, "issueCode", $$v)
                },
                expression: "alertSettingsItem.issueCode",
              },
            })
          : [_vm._v(_vm._s(_vm.alertSettingsItem.issueCode))],
      ],
      2
    ),
    _c(
      "span",
      [
        _vm.isEditing || _vm.alertSettingsItem.isEditMode
          ? _c("drop-down-box", {
              attrs: {
                id: "alert-type-" + _vm.index,
                value: _vm.alertSettingsItem.alertType,
                placeholder: "Web 알림",
                optionItemsData: Object.keys(_vm.alertSettingsOptionNames),
                optionNames: _vm.alertSettingsOptionNames,
              },
              on: {
                selectedOption: function ($event) {
                  var i = arguments.length,
                    argsArray = Array(i)
                  while (i--) argsArray[i] = arguments[i]
                  return _vm.onSelectedOption.apply(
                    void 0,
                    [_vm.alertSettingsItem].concat(argsArray)
                  )
                },
              },
            })
          : [
              _vm._v(
                _vm._s(
                  _vm.alertSettingsOptionNames[_vm.alertSettingsItem.alertType]
                )
              ),
            ],
      ],
      2
    ),
    _c(
      "span",
      [
        _vm.isEditing || _vm.alertSettingsItem.isEditMode
          ? _c("input-box", {
              attrs: {
                type: "text",
                id: "alert-title-" + _vm.index,
                maxlength: 10,
                autocomplete: "off",
                "is-flex": true,
              },
              model: {
                value: _vm.alertSettingsItem.title,
                callback: function ($$v) {
                  _vm.$set(_vm.alertSettingsItem, "title", $$v)
                },
                expression: "alertSettingsItem.title",
              },
            })
          : [_vm._v(_vm._s(_vm.alertSettingsItem.title))],
      ],
      2
    ),
    _c(
      "span",
      [
        _vm.isEditing || _vm.alertSettingsItem.isEditMode
          ? _c("input-box", {
              attrs: {
                type: "text",
                id: "alert-description-" + _vm.index,
                maxlength: 50,
                autocomplete: "off",
                "is-flex": true,
              },
              model: {
                value: _vm.alertSettingsItem.description,
                callback: function ($$v) {
                  _vm.$set(_vm.alertSettingsItem, "description", $$v)
                },
                expression: "alertSettingsItem.description",
              },
            })
          : [_vm._v(" " + _vm._s(_vm.alertSettingsItem.description) + " ")],
      ],
      2
    ),
    _c(
      "span",
      [
        _vm.isEditing || _vm.alertSettingsItem.isEditMode
          ? [
              _vm.alertSettingsItem.rscFileId
                ? _c("photo", {
                    attrs: {
                      isShowDeleteButton: true,
                      imgSrc: _vm.alertSettingsItem.refImgUrl,
                    },
                    on: {
                      delete: function ($event) {
                        return _vm.onDeletePhoto(_vm.alertSettingsItem)
                      },
                    },
                  })
                : _c(
                    "file-basic",
                    {
                      staticClass: "text-button text-button-gray",
                      attrs: {
                        accept: ".jpg, .png",
                        id: "alert-file-" + _vm.index,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onSelectFile($event, _vm.alertSettingsItem)
                        },
                      },
                    },
                    [_vm._v(" 추가 ")]
                  ),
            ]
          : [
              _vm.alertSettingsItem.refImgUrl
                ? _c("photo", {
                    attrs: {
                      isShowDeleteButton: false,
                      imgSrc: _vm.alertSettingsItem.refImgUrl,
                    },
                  })
                : _c("span", [_vm._v("-")]),
            ],
      ],
      2
    ),
    _c(
      "span",
      [
        _vm.isNotFactoryEquipment
          ? [
              _vm.isEditing || _vm.alertSettingsItem.isEditMode
                ? [
                    _c(
                      "button-basic",
                      {
                        attrs: { color: "gray-border-1", size: "ss" },
                        on: { click: _vm.toggleIsEditing },
                      },
                      [_vm._v("취소")]
                    ),
                    _c(
                      "button-basic",
                      {
                        attrs: {
                          color: "dark",
                          size: "ss",
                          disabled: _vm.buttonDisabled,
                        },
                        on: { click: _vm.onSaveAlertSetting },
                      },
                      [_vm._v(" 저장 ")]
                    ),
                  ]
                : [
                    _c(
                      "button",
                      {
                        staticClass: "text-button text-button-gray",
                        on: { click: _vm.onDeleteAlertSettings },
                      },
                      [_vm._v("삭제")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "text-button text-button-gray",
                        on: { click: _vm.toggleIsEditing },
                      },
                      [_vm._v("수정")]
                    ),
                  ],
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }