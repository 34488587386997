var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white has-no-scroll" },
    [
      _c("main-section-header", {
        attrs: { title: "설비그룹 상세보기", "is-sticky": true },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "설비그룹 정보" } }),
          !_vm.isLoading
            ? [
                _c(
                  "ul",
                  {
                    staticClass: "detail-list",
                    class: { "factory-list": _vm.isFactoryComp },
                  },
                  _vm._l(_vm.equipGroupInfo, function (eqpInfo, index) {
                    return _c(
                      "detail-list-row",
                      {
                        key: index,
                        attrs: {
                          "is-register": false,
                          info: eqpInfo,
                          inputKey: eqpInfo.key,
                          "input-value": _vm.equipmentGroupData[eqpInfo.key],
                          "text-count": eqpInfo.textCount,
                          maxlength: eqpInfo.maxlength,
                          placeholder: eqpInfo.placeholder,
                          fileName: _vm.showedFileName,
                          "is-loading":
                            eqpInfo.key === "manualFileUrl"
                              ? _vm.isLoadingFile
                              : false,
                        },
                        on: {
                          selectUploadData: _vm.onSelectUploadData,
                          deleteUploadData: _vm.onDeleteUploadData,
                          saveChangeValue: _vm.onSaveInputValue,
                          saveInputValue: _vm.onSaveEditValue,
                          createAlertSettingsItem:
                            _vm.onCreateAlertSettingsItem,
                          clickCancelButton: _vm.onClickCancelButton,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "label",
                            class: { "label-required": eqpInfo.isRequired },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [_vm._v(" " + _vm._s(eqpInfo.label) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "contents",
                            attrs: { slot: "contents" },
                            slot: "contents",
                          },
                          [
                            _vm.hasEqpGroupData.includes(eqpInfo.key)
                              ? _c(
                                  "button",
                                  {
                                    class: {
                                      "has-no-data":
                                        !_vm.equipmentGroupData[eqpInfo.key],
                                      "group-name": eqpInfo.key === "groupName",
                                    },
                                    on: {
                                      click: function ($event) {
                                        var _obj
                                        $event.stopPropagation()
                                        return _vm.onClickFileOrLink(
                                          ((_obj = {}),
                                          (_obj[eqpInfo.clickType] =
                                            _vm.equipmentGroupData[
                                              eqpInfo.key
                                            ]),
                                          _obj)
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.equipmentGroupData[
                                            eqpInfo.name
                                          ] ||
                                            _vm.equipmentGroupData[
                                              eqpInfo.key
                                            ] ||
                                            "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-50 ml-20 mr-20" },
                  [
                    _c("alert-settings-list", {
                      attrs: {
                        "alert-settings-list":
                          _vm.equipmentGroupData.alertSettings,
                        "group-type": _vm.equipmentGroupData.groupType,
                      },
                      on: {
                        selectUploadData: _vm.onSelectUploadData,
                        deleteUploadData: _vm.onDeleteUploadData,
                        deleteAlertSetting: _vm.togglePopup,
                        saveAlertSetting: _vm.onSetAlertSettingType,
                        deleteAlertItem: _vm.onDeleteAlertItem,
                      },
                    }),
                  ],
                  1
                ),
                _c("subtitle", {
                  staticClass: "mb-20",
                  attrs: { subtitle: "연결된 설비 정보" },
                }),
                _c(
                  "div",
                  { staticClass: "mb-30" },
                  [
                    _c("linked-group-equipment-list", {
                      attrs: {
                        "linked-equip-list": _vm.equipmentGroupData.equipments,
                        "group-type": _vm.equipmentGroupData.groupType,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "list-bottom-button-box",
                  [
                    _vm.isNotFactoryEquipment
                      ? _c(
                          "button-basic",
                          {
                            attrs: {
                              slot: "button",
                              color: "gray-border-1",
                              size: "m",
                              width: 150,
                            },
                            on: { click: _vm.onShowDeleteEqpGroupPopup },
                            slot: "button",
                          },
                          [_vm._v(" 설비그룹 삭제 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ConfirmPopup",
          "contents-key": _vm.popupContentsKey,
        },
        on: { close: _vm.onCloseConfirmPopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }