<template>
  <li>
    <span>{{ index + 1 }}</span>
    <span>
      <input-box
        v-if="isEditing || alertSettingsItem.isEditMode"
        type="text"
        :id="`alert-code-${index}`"
        v-model="alertSettingsItem.issueCode"
        autocomplete="off"
        :is-flex="true"
      />
      <template v-else>{{ alertSettingsItem.issueCode }}</template>
    </span>
    <span>
      <drop-down-box
        v-if="isEditing || alertSettingsItem.isEditMode"
        :id="`alert-type-${index}`"
        :value="alertSettingsItem.alertType"
        placeholder="Web 알림"
        :optionItemsData="Object.keys(alertSettingsOptionNames)"
        :optionNames="alertSettingsOptionNames"
        @selectedOption="onSelectedOption(alertSettingsItem, ...arguments)"
      />
      <template v-else>{{ alertSettingsOptionNames[alertSettingsItem.alertType] }}</template>
    </span>
    <span>
      <input-box
        v-if="isEditing || alertSettingsItem.isEditMode"
        type="text"
        :id="`alert-title-${index}`"
        :maxlength="10"
        v-model="alertSettingsItem.title"
        autocomplete="off"
        :is-flex="true"
      />
      <template v-else>{{ alertSettingsItem.title }}</template>
    </span>
    <span>
      <input-box
        v-if="isEditing || alertSettingsItem.isEditMode"
        type="text"
        :id="`alert-description-${index}`"
        :maxlength="50"
        v-model="alertSettingsItem.description"
        autocomplete="off"
        :is-flex="true"
      />
      <template v-else>
        {{ alertSettingsItem.description }}
      </template>
    </span>
    <span>
      <template v-if="isEditing || alertSettingsItem.isEditMode">
        <photo
          v-if="alertSettingsItem.rscFileId"
          :isShowDeleteButton="true"
          @delete="onDeletePhoto(alertSettingsItem)"
          :imgSrc="alertSettingsItem.refImgUrl"
        />
        <file-basic
          v-else
          accept=".jpg, .png"
          :id="`alert-file-${index}`"
          class="text-button text-button-gray"
          @change="onSelectFile($event, alertSettingsItem)"
        >
          추가
        </file-basic>
      </template>
      <template v-else>
        <photo :isShowDeleteButton="false" :imgSrc="alertSettingsItem.refImgUrl" v-if="alertSettingsItem.refImgUrl" />
        <span v-else>-</span>
      </template>
    </span>
    <span>
      <template v-if="isNotFactoryEquipment">
        <template v-if="isEditing || alertSettingsItem.isEditMode">
          <button-basic color="gray-border-1" size="ss" @click="toggleIsEditing">취소</button-basic>
          <button-basic color="dark" size="ss" @click="onSaveAlertSetting" :disabled="buttonDisabled">
            저장
          </button-basic>
        </template>
        <template v-else>
          <button class="text-button text-button-gray" @click="onDeleteAlertSettings">삭제</button>
          <button class="text-button text-button-gray" @click="toggleIsEditing">수정</button>
        </template>
      </template>
    </span>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import InputBox from '@/component/input/InputBox';
import DropDownBox from '@/component/dropDown/DropDownBox';
import Photo from '@/component/photoView/Photo';

export default {
  name: 'Test',
  props: ['alertSettingsItem', 'index', 'groupType'],
  data() {
    return {
      alertSettingsOptionNames: {
        WEB: 'Web 알림',
        APP: 'App. 알림',
        BOTH: '둘 다 알림',
        STOP: '알림 중단',
      },
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters('users', ['isManufacturerComp']),
    buttonDisabled() {
      return !this.alertSettingsItem.issueCode || !this.alertSettingsItem.title || !this.alertSettingsItem.description;
    },
    isNotFactoryEquipment() {
      return this.groupType === 'F' || (this.isManufacturerComp && this.groupType === 'M');
    },
  },
  created() {
    if (this.alertSettingsItem.isEditMode) {
      this.isEditing = true;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    toggleIsEditing() {
      this.isEditing = !this.isEditing;
      if (this.alertSettingsItem.isEditMode) {
        this.onDeleteAlertItem(this.index);
      }
    },
    onSelectedOption(alertSettingsItem, selectedAlertOption) {
      alertSettingsItem.alertType = selectedAlertOption;
    },
    onSelectFile(e, alertSettingsItem) {
      this.$emit('selectUploadData', { event: e, alertSettingsItem, type: 'photo' });
    },
    onDeleteAlertItem(itemIndex) {
      this.$emit('deleteAlertItem', itemIndex);
    },
    onDeletePhoto() {
      this.$emit('deleteUploadData', 'photo', this.alertSettingsItem);
    },
    onSaveAlertSetting() {
      this.$emit('saveAlertSetting', this.alertSettingsItem);
      this.isEditing = !this.isEditing;
      this.alertSettingsItem.isEditMode = false;
    },
    onDeleteAlertSettings() {
      this.$emit('deleteAlertSetting', this.alertSettingsItem.id);
    },
  },
  components: { InputBox, DropDownBox, Photo },
};
</script>

<style scoped lang="scss">
@import 'AlertSettingsItem';
</style>
