<template>
  <list :list="alertSettingsList" :header-list="alertSettingsHeaderList" :has-scroll-list="true" :is-edit="true">
    <alert-settings-item
      slot="list-body"
      v-for="(alertSettingsItem, index) in alertSettingsList"
      :key="index"
      :alert-settings-item="alertSettingsItem"
      :index="index"
      :group-type="groupType"
      @selectUploadData="onSelectFile"
      @deleteAlertItem="onDeleteAlertItem"
      @deleteUploadData="onDeletePhoto"
      @saveAlertSetting="onSaveAlertSetting"
      @deleteAlertSetting="onDeleteAlertSetting"
    />
    <p slot="no-list-description" class="font-16">등록된 알람 설정이 없습니다.</p>
  </list>
</template>

<script>
import List from '@/component/list/List';
import ListHeaderData from '../../equipmentGroupsData/ListHeaderData.json';
import AlertSettingsItem from '@/view/equipmentGroups/equipmentGroupDetail/alertSettingsList/alertSettingsItem/AlertSettingsItem';

export default {
  name: 'AlertSettingsList',
  props: ['alertSettingsList', 'groupType'],
  data() {
    return {
      alertSettingsHeaderList: ListHeaderData,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onSelectFile({ event, alertSettingsItem = null, type }) {
      this.$emit('selectUploadData', { event: event, alertSettingsItem, type: type });
    },
    onDeleteAlertItem(itemIndex) {
      this.$emit('deleteAlertItem', itemIndex);
    },
    onDeletePhoto(uploadType, alertSettingsItem) {
      this.$emit('deleteUploadData', uploadType, alertSettingsItem);
    },
    onSaveAlertSetting(alertSettingsItem) {
      this.$emit('saveAlertSetting', alertSettingsItem);
    },
    onDeleteAlertSetting(alertId) {
      this.$emit('deleteAlertSetting', 'ConfirmPopup', 'DELETE_ALERT_SETTING', alertId);
    },
  },
  components: { List, AlertSettingsItem },
};
</script>

<style scoped lang="scss">
@import '../../equipmentGroupRegister/alertSettingsList/AlertSettingsList';
</style>
