var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "list",
    {
      attrs: {
        list: _vm.alertSettingsList,
        "header-list": _vm.alertSettingsHeaderList,
        "has-scroll-list": true,
        "is-edit": true,
      },
    },
    [
      _vm._l(_vm.alertSettingsList, function (alertSettingsItem, index) {
        return _c("alert-settings-item", {
          key: index,
          attrs: {
            slot: "list-body",
            "alert-settings-item": alertSettingsItem,
            index: index,
            "group-type": _vm.groupType,
          },
          on: {
            selectUploadData: _vm.onSelectFile,
            deleteAlertItem: _vm.onDeleteAlertItem,
            deleteUploadData: _vm.onDeletePhoto,
            saveAlertSetting: _vm.onSaveAlertSetting,
            deleteAlertSetting: _vm.onDeleteAlertSetting,
          },
          slot: "list-body",
        })
      }),
      _c(
        "p",
        {
          staticClass: "font-16",
          attrs: { slot: "no-list-description" },
          slot: "no-list-description",
        },
        [_vm._v("등록된 알람 설정이 없습니다.")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }